import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import StoreIcon from '@mui/icons-material/Store';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Business (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	let { businessCat } = useSelector((store) => store.businessCategory);
	if(!businessCat) businessCat = localStorage.getItem("businessCategory");
	const handleClick = () => {setOpen(!open);}
	console.log("es", props.userGroup)
	const state = localStorage.getItem("workflow")
	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>

			{/*Business*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff'}}>
				<div style={{marginRight : '5%'}}>
					<StoreIcon />
				</div>

				<ListItemText primary="Business" style={{fontFamily : 'Poppins' , color: open ? '#04f2fc' : '#fff'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Business*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{/*/shops*/}

					 {/* {businessCat === "School" && state !== "PARENT"  ?
						// navigate("");
						<Link style={{textDecoration: 'none', color : '#fff'}} to="/school/setup">
							<ListItem button className={classes.nested}>
								<ListItemText primary="Continue Setup" />
							</ListItem>
						</Link>
						:  */}
						{
						 <div style={{ marginLeft: '8%' }}>
							<Link style={{textDecoration: 'none', color: isActiveLink("/shops") ? activeColor : '#fff'}} to={"/shops"}>
								<ListItem button className={classes.nested}>
									<ListItemText primary="My Business" />
								</ListItem>
							</Link>

							<Link style={{ color: isActiveLink("/requestjoinbusiness") ? activeColor : '#fff', textDecoration: 'none'}} to="/requestjoinbusiness">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Request To Join Business"/>
								</ListItem>
							</Link>
							<Link style={{color: isActiveLink("/approverequests") ? activeColor : '#fff', textDecoration: 'none'}} to="/approverequests">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Approve Join Request"/>
									</ListItem>
							</Link>
							</div>
					}

					{/*<Link style={{color: '#fff', textDecoration: 'none'}} to="/invoices">*/}
					{/*	<ListItem button className={classes.nested}>*/}
					{/*		<ListItemText primary="Invoices"/>*/}
					{/*	</ListItem>*/}
					{/*</Link>*/}

					{/*<Link style={{color: '#fff', textDecoration: 'none'}} to="/receipts">*/}
					{/*	<ListItem button className={classes.nested}>*/}
					{/*		<ListItemText primary="Receipts"/>*/}
					{/*	</ListItem>*/}
					{/*</Link>*/}

				</List>
			</Collapse>
		</div>
	)
}

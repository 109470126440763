import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistedStore, store } from './store/store';
import './index.css';
import App from './App';
import './common/font/Poppins-Regular.ttf'
import { ErrorBoundary } from 'react-error-boundary';
import { fallBackError } from './common/FallBackComponent';
//just a comment
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary fallbackRender={fallBackError} >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistedStore}>
                    <App />
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>
);


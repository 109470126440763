import { useState, useEffect} from "react"
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import StoreIcon from '@mui/icons-material/Store';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Business (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const businessCat = localStorage.getItem("businessCategory")

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')

	const isSupervisor = userGroup && userGroup === "Supervisor"
	const isStoreManager = userGroup && userGroup.toLocaleLowerCase().includes("storemanager")
	const isStoreOperator = userGroup && userGroup.toLocaleLowerCase().includes("storeoperator")

	const storeUsers = isStoreManager || isStoreOperator

	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"


	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{marginRight : '5%'}}>
					<StoreIcon />
				</div>
				{businessCat === "School" ?
					<ListItemText primary="Institutions" style={{fontFamily : 'Poppins' ,color: open ? '#04f2fc' : '#fff'}}/>
					:
					<ListItemText primary="Businesses" style={{fontFamily : 'Poppins' ,color: open ? '#04f2fc' : '#fff'}}/>
				}
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>


						<div>
						{userGroup === 'Admin' || userGroup === 'Merchant' || userGroup === 'Customer' || userGroup === "Cashier"  || userGroup === "Owner" || isSupervisor || storeUsers ?
							<Link style={{textDecoration: 'none', color: isActiveLink("/shops") ? activeColor : '#fff'}} to="/shops">
								<ListItem button className={classes.nested}>
									<ListItemText primary="My Business" />
								</ListItem>
							</Link>
							: null}
							{userGroup == 'Admin' || userGroup === 'Customer' || userGroup === 'Merchant' || userGroup === "Owner" || storeUsers ?
								<>
								<Link style={{color: isActiveLink("/requestjoinbusiness") ? activeColor : '#fff', textDecoration: 'none'}} to="/requestjoinbusiness">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Request to Join Business"/>
									</ListItem>
								</Link>

								<Link style={{color: isActiveLink("/approverequests") ? activeColor : '#fff', textDecoration: 'none'}} to="/approverequests">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Approve Join Request"/>
									</ListItem>
								</Link>
								{userGroup !== 'Customer' || userGroup !== 'STOREMANAGER' ||userGroup !==  'Cashier' || userGroup !==  'StoreOperator'  ?(
								<Link style={{color: isActiveLink("/shop/businessinfo") ? activeColor : '#fff' , textDecoration: 'none'}} to="/shop/businessinfo">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Business Info"/>
									</ListItem>
								</Link>):null}
								<Link style={{color: isActiveLink("/mainstores") ? activeColor : '#fff', textDecoration: 'none'}} to="/mainstores">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Branches"/>
									</ListItem>
								</Link>
								</>
							:null}
						</div>

				</List>
			</Collapse>
		</div>
	)
}

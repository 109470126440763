import React, { useState } from "react";
import { Grid, Button, Card, Stack, Box, Tab, styled, Breadcrumbs, Typography } from "@mui/material";
import Dash from "../../../common/dash";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import customerDetailssvg from "../../../common/images/customeerdetailssvg.svg";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import emptyState from "../../../common/images/emptystate.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import emptyInvoice from "../../../common/images/invoice-empty.svg";
import AddIcon from "@mui/icons-material/Add";
import { ReceivedPayment } from "../../Modals/Invoice/receivedPayment";
import { MpesaPayments } from "../../Modals/Invoice/mpesaMessages";
import { CardPayments } from "../../Modals/Invoice/receiveCardpayment";
import { PayInvoice } from "../../Modals/Invoice/payInvoice";
import { customerstyles } from "./styles";
import SearchFilter from "./searchFilter";
import { Exports } from "./exports";
import { CustomDate, gridDateFormater } from "./customDate";
import { ArrowBackIos } from "@mui/icons-material";
import Statements from "./statement";
import Customeractivity from "./customeractivity";
import Customertransaction from "./customertransaction";
import CustomerInvoice from "./customerinvoice";
import StatementPreveiw from "./statementpreview";
import { SaveAlt } from "@mui/icons-material";
import { doPrintDocs } from "../../../features/startorderSlice";
import { useDispatch } from "react-redux";
import { ErrorAlertRightAligned } from "../../bills/startorderModals/modals";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomerOrders from "./customerOrders";
import RequestPayFromCustomer from "./requestPayFromCustomer";
const baseUrl = process.env.REACT_APP_BASE_URL;
const getCustomerDetailsUrl = baseUrl + "/api/get_customer_by_number";
const getCustomerActivities = baseUrl + "/api/customerTransactions";
const getCustomerActivityUrl = baseUrl + "/api/CustomersActivities";
const filterTranactionUrl = baseUrl + "/api/filterCustomerTransactionByDate";

//  let date = createdAt.split("T")[0];
//  let day = date.split("-");

//breadcrumbs

const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Customers</Typography>
]

// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));



const Customerdetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
  // set tabChange;

  const [inputValue, setInputValue] = useState()

  // url params
  const { id } = useParams();
  const [customerDetails, setCustomerDetails] = useState([]);

  const queryParams = decodeURIComponent(window.location.search);
  const state = queryParams.slice(1)

  // get customer details;

  const getCustomerDetails = async () => {
    try {
      const customerDetailsResponse = await fetch(getCustomerDetailsUrl + `?customerId=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });



      if (customerDetailsResponse.status === 200) {
        const customerDetailsData = await customerDetailsResponse.json();
        setCustomerDetails(customerDetailsData.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [id]);

  const [customerInfo, setCustomerInfo] = useState({
    customerName: "",
    dayCreated: "",
    customerType: "",
    adress: "",
    paymentType: "",
    currentBalance: "",
    totalCredited: "",
    totalexpenses: "",
    email: "",
    phone: "",
  });

  const getCustomerData = () => {
    customerDetails.length > 0 &&
      customerDetails.forEach((customer) => {
        const date = new Date(customer?.createdAt).toDateString();
        setCustomerInfo({
          ...customerInfo,
          customerName: customer?.firstName + " " + customer?.lastName,
          dayCreated: date,
          customerType: customer?.customerType,
          adress: customer?.customerAddress,
          paymentType: customer?.paymentType,
          currentBalance: !customer?.totalBalance ? 0 : customer?.paymentType === "Normal" ? "N/A" : customer?.totalBalance,
          totalCredited: customer?.paymentType === "Normal" ? "N/A" : customer?.limit,
          totalexpenses: customer?.totalExpense,
          email: customer?.email,
          phone: customer?.phone,
        });
      });
  };

  useEffect(() => {
    getCustomerData();
  }, [customerDetails]);

  // set tab values;
  const [tabvalue, setChangeTabValue] = useState("Transactions");
  const handleChangeTabValue = (e, newValue) => {
    setChangeTabValue(newValue);
  };

  const [customerDetailsData, setCustomerDetailsData] = useState([]);

  const customerDataRow =
    customerDetailsData?.length &&
    customerDetailsData?.map((customer) => ({
      id: customer?._id,
      amount: customer?.amount,
      transactionType: customer?.transactionType,
      createdBy: customer?.createdBy,
      createdAt: customer?.createdAt,
    }));

  // FILTER BY DATE;
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  // console.log('dateFrom ======<><>', dateFrom, dateTo);
  useEffect(() => {
    // if(dateFrom && dateTo)
    // getFilteredData(dateFrom, dateTo);
  }, [dateFrom, dateTo, id]);

  // download statements;
  const [printPDF, setPrintPDF] = useState(false);
  const [noViewStament, setNoviewStatement] = useState(false);
  const downloadPDF = () => {
    const input = document.getElementById(idToPrint);
    // const input =  <StatementPreveiw customerId={id} />
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
      pdf.save(`receipt.pdf`);
    });
  };

  const hasBranch = localStorage.getItem("BranchID")
  const [error, setError] = useState({ state: false, message: '' })

  useEffect(() => {
    if (error.state) {
      setTimeout(() => {
        setError({ state: false })
      }, 2000);

    }
  }, [error.state])

  const [orderIds, setOrderIds] = useState([])

  return (
    <div style={customerstyles.universalStyle}>
      {state === "requestPayment" ? <RequestPayFromCustomer orderIDS={orderIds} /> : <>

        <div>
          <Grid container direction={'column'}>
            <Grid item>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#032541",
                }}
              >
                <ArrowBackIos onClick={() => navigate(`/customers`)} />
                {customerInfo?.customerName}
              </span>
            </Grid>
            <Grid item mt={1} mb={2}>
              <Grid container display={'flex'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                <Grid item>
                  <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  {/* <Button
              style={{
                width: "167px",
                height: "45px",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
                border:"2px solid #032541",
                backgroundColor: "#fff",
                textTransform:"inherit",
                color:"#032541",
                marginRight:'10px'
                // padding: "13px 36px 12px"
              }}
            >
              Credit Account
            </Button> */}
                  <Button
                    style={{
                      width: "167px",
                      height: "45px",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 500,
                      border: "2px solid #dc3545",
                      backgroundColor: "#fff",
                      textTransform: "inherit",
                      color: "#dc3545"
                      // padding: "13px 36px 12px"
                    }}
                  >
                    Suspend Account
                  </Button>
                  <Button
                    style={{
                      width: "175px",
                      height: "45px",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "#032541",
                      // borderRadius:"5px",
                      color: "white",
                      margin: "0 12px",
                      padding: "9px 12px 8px",
                      textTransform: "inherit",
                      // padding: "13px 36px 12px"
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => hasBranch === 'undefined' ? setError({ state: true, message: 'Select a branch to continue!' }) : navigate(`/createInvoice/${id}`)}
                  >
                    Create Invoice
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* CUSTOMER DETAILS; */}

          <Grid container direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"} style={{ backgroundColor: "#f5f5f5", padding: "20px 10px 20px 10px", margin: "0px 0px 10px 0px" }}>
            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Customer Type</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.customerType}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Email</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.email}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Phone</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.phone}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Physical Adress</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.adress}</p>
            </Grid>
            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Payment Type</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.paymentType}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsParaBalance}>Current Balance</p>
              <p style={customerstyles.customerDetailsParaNormalBalance}>{customerInfo?.currentBalance}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Total Credited</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.totalCredited}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsParaExpense}>Total Spend</p>

              <p style={customerstyles.customerDetailsParaNormalExpense}>{customerInfo?.totalexpenses}</p>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item style={{ width: "100%" }}>
              <TabContext value={tabvalue}>
                <Box mb={3} style={{ width: "100%" }}>
                  <AntTabs onChange={handleChangeTabValue}>
                    <AntTab label="Transactions" value="Transactions" />
                    <AntTab label="Activity" value="Activity" />
                    <AntTab label="Reciepts" value="Reciepts" />
                    <AntTab label="Invoices" value="Invoice" />
                    <AntTab label="Orders" value="Orders" />
                  </AntTabs>
                </Box>

                {/* search */}
                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                  <Grid item style={customerstyles.searchDateFilter}>
                    <SearchFilter setInputValue={setInputValue} />
                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                  </Grid>

                  <Grid item>
                    <Grid container>
                      <Grid item mr={3}>
                        <Button onClick={() => navigate(`/viewstatement/${id}`)} style={customerstyles.viewStatementBtn} startIcon={<SaveAlt style={{ cursor: "pointer", color: "#032541", fontSize: "25px" }} />}>View Statement</Button>
                      </Grid>
                      <Grid item>
                        <Exports exportData={exportData} activeTabs={tabvalue} csvExport={csvExport} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <TabPanel value="Transactions">
                  <Customertransaction customerId={id} />
                </TabPanel>

                <TabPanel value="Activity">
                  <Customeractivity customerId={id} />
                </TabPanel>

                <TabPanel value="Reciepts">
                  <Customeractivity customerId={id} />
                </TabPanel>

                <TabPanel value="Invoice">
                  <CustomerInvoice customerId={id} />
                </TabPanel>
                <TabPanel value="Orders">
                  <CustomerOrders setOrderIds={setOrderIds} customerId={id} />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>

          {error.state && <ErrorAlertRightAligned error={error.state} message={error.message} />}
        </div>
      </>}

    </div>
  );
};

export default Customerdetails;

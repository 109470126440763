import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, Select, Slide, Tab, Typography, alpha, styled } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { forwardRef, useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import ExportMenu from "../School/ExportMenu";
import HttpComponent from "../School/MakeRequest";
import DateFormatter from "../../utils/dateFormatter";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import approveImg from "../../images/Appro.svg"
import cancelImg from "../../images/crosImg.svg"
import warningImg from "../../images/warning.png"
import AddRoles from "./selectRoles";
import { useNavigate } from "react-router-dom";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Join Requests</Typography>,
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


export default function ApproveJoinBs() {
    const [value, setValue] = useState('PENDING');
    const [searchValue, setInputValue] = useState('')
    const [role, setRole] = useState('')
    const [branch, setBranch] = useState('')
    const [openApprove, setOpenApprove] = useState(false)

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const handleOpenApprove = () => setOpenApprove(true)
    const handleCloseApprove = () => setOpenApprove(false)

    const [opendeclineModal, setOpendeclineModal] = useState(false)

    const handleOpenDecline = () => setOpendeclineModal(true)
    const handleCloseDecline = () => setOpendeclineModal(false)
    const [aproveData ,setApproveData] = useState()

    const queryParams = decodeURIComponent(window.location.search)
    const state = queryParams.slice(1)

    const navigate = useNavigate()


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //fetch all the requests

    function fetchBusinessRequest() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'POST',
                body: { businessId: localStorage.getItem('businessID') },
                url: `/api/get_requests_to_join_business?status=${value}&limit=${dataGridPageSize}&page=${pageState.page}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    if (data.status === 200) {
                        setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    }
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchBusinessRequest()
    }, [value ,state])

    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const [declineRowData, setDeclineRowData] = useState()
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => {
            setAnchorEl(null);
        };

        function handleDeclineJoinRequest() {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/decline_join_business`,
                    body: {
                        businessId: declineRowData?.businessId,
                        requestId: declineRowData?.requestId,
                        status: "REJECTED"
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if(data.status === 201){
                        setSuccessShow({state:true , message:'successfully declined to join buisness'})
                        
                    }else{
                        setErrorShow({state:true , message:'Error in declineing to join buisness'})
                    }
                    fetchBusinessRequest()

                })

            } catch (error) {
                console.log(error)
            }
        }

        // navigate 

        const addRoles = ()=>{
            navigate('/approverequests?approveRequest')
            handleCloseApprove()
        }


        return (
            <div>
                <Dialog
                    open={openApprove}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img width="120px" src={approveImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Approve Join Request</h6>
                            <span>Do you want to approve this request  </span> <br></br>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Button onClick={handleCloseApprove} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={addRoles} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Approve</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={opendeclineModal}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img width="120px" src={warningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Decline Join Request</h6>
                            <span>Do you want to decline this request  </span> <br></br>
                        </div>
                    </DialogContent>
                        <DialogActions sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <Button onClick={handleCloseDecline} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                            <Button onClick={handleDeclineJoinRequest} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Decline</Button>
                        </DialogActions>
                </Dialog>

                <MoreVertIcon onClick={handleClick} />
                <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                    <MenuItem disableRipple onClick={()=>{handleOpenApprove();setApproveData(params?.row); setAnchorEl(null)}} style={{ display: "flex", alignCenter: "center" }}>
                        <img width="20px" height={'20px'} style={{ marginRight: "10px" }} src={approveImg} alt="approve" /> Approve
                    </MenuItem>
                    <MenuItem disableRipple onClick={() => { handleOpenDecline(); setDeclineRowData(params?.row);setAnchorEl(null) }} style={{ display: "flex", alignCenter: "center" }}>
                        <img src={cancelImg} style={{ marginRight: "10px" }} alt="decline" /> Decline
                    </MenuItem>
                </StyledMenu>
            </div>
        )
    }

    const pendingRequest = [
        { headerName: "Name", field: 'userName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "Mobile No", field: 'phone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile No</strong>) } },
        { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
        {
            headerName: "Requested On", field: 'requestDate', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Requested On</strong>) }, renderCell: (params) => {
                return (
                    <>
                        <span>{DateFormatter(params?.row?.requestDate)}</span>
                    </>
                )
            }
        },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell },

    ]

    const acceptedRequestCols = [
        { headerName: "Name", field: 'userName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "Mobile No", field: 'phone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile No</strong>) } },
        { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
        {
            headerName: "Requested On", field: 'requestDate', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Requested On</strong>) }, renderCell: (params) => {
                return (
                    <>
                        <span>{DateFormatter(params?.row?.requestDate)}</span>
                    </>
                )
            }
        },
    ]

    const rejectedRequestCols = [
        { headerName: "Name", field: 'userName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "Mobile No", field: 'phone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile No</strong>) } },
        { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
        {
            headerName: "Requested On", field: 'requestDate', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Requested On</strong>) }, renderCell: (params) => {
                return (
                    <>
                        <span>{DateFormatter(params?.row?.requestDate)}</span>
                    </>
                )
            }
        },
    ]

    return (
        <Grid container direction={'column'}>
            {state === 'approveRequest' ? <AddRoles approveData={aproveData} /> :
                <>
                     <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Join Business</Typography>
                    </Grid>
                    <Grid item my={2}>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <TabContext value={value}>
                            <AntTabs onChange={handleChange}>
                                <AntTab label="Pending" value="PENDING" />
                                <AntTab label="Approved" value="ACCEPTED" />
                                <AntTab label="Declined" value="REJECTED" />
                            </AntTabs>
                            <TabPanel value={"PENDING"}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid container display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid item>
                                                <FormControl sx={{ width: "300px", marginRight: "10px" }} variant="outlined">
                                                    <OutlinedInput
                                                        sx={{
                                                            "&.Mui-selected": {
                                                                outline: "none"
                                                            },
                                                        }}
                                                        placeholder={'Search'}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        id="outlined-adornment-Search"
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                        aria-describedby="outlined-weight-helper-text"
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={role}
                                                        label="Role"
                                                        onChange={(e) => setRole(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Store Manager</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={branch}
                                                        label="Role"
                                                        onChange={(e) => setBranch(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Utopia</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <ExportMenu />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState.total}
                                            loading={pageState.isLoading}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={pendingRequest}
                                            rows={pageState?.data}
                                            getRowId={row => row?.requestId} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={"ACCEPTED"}>
                            <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid container display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid item>
                                                <FormControl sx={{ width: "300px", marginRight: "10px" }} variant="outlined">
                                                    <OutlinedInput
                                                        sx={{
                                                            "&.Mui-selected": {
                                                                outline: "none"
                                                            },
                                                        }}
                                                        placeholder={'Search'}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        id="outlined-adornment-Search"
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                        aria-describedby="outlined-weight-helper-text"
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={role}
                                                        label="Role"
                                                        onChange={(e) => setRole(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Store Manager</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={branch}
                                                        label="Role"
                                                        onChange={(e) => setBranch(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Utopia</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <ExportMenu />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState.total}
                                            loading={pageState.isLoading}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={acceptedRequestCols}
                                            rows={pageState?.data}
                                            getRowId={row => row?.requestId} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={"REJECTED"}>
                            <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid container display={'flex'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid item>
                                                <FormControl sx={{ width: "300px", marginRight: "10px" }} variant="outlined">
                                                    <OutlinedInput
                                                        sx={{
                                                            "&.Mui-selected": {
                                                                outline: "none"
                                                            },
                                                        }}
                                                        placeholder={'Search'}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        id="outlined-adornment-Search"
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                        aria-describedby="outlined-weight-helper-text"
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={role}
                                                        label="Role"
                                                        onChange={(e) => setRole(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Store Manager</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ width: "180px", marginLeft: "10px" }}>
                                                    <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={branch}
                                                        label="Role"
                                                        onChange={(e) => setBranch(e.target.value)}
                                                    >
                                                        {/* <MenuItem value={10}>Utopia</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <ExportMenu />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState.total}
                                            loading={pageState.isLoading}
                                            pagination
                                            page={pageState.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={rejectedRequestCols}
                                            rows={pageState?.data}
                                            getRowId={row => row?.requestId} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </>
            }
        </Grid>
    )
}
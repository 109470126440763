import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

export default function OtherBusinessConvenienceFee () {
    const classes = useStyles();

    let {businessCat} = useSelector((store)=>store.businessCategory)
    if(!businessCat) businessCat = localStorage.getItem("businessCategory")


    return (
        <div>
            <Link style={{textDecoration: 'none', color : '#fff'}} to={"/conviniencefee/otherbusiness"}>
                <ListItem button className={classes.nested} disablePadding>
                    <div style={{marginRight : '5%'}}>
                        <PriceChangeIcon/>
                    </div>
                    <div>
                        <ListItemText primary="Convenience Fee Mgt" />
                    </div>
                </ListItem>
            </Link>
        </div>
    )
}

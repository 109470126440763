import { Box, Button, FormControl, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import HttpComponent from "../MakeRequest";

const Accessbankdeposit = ({ setShowAccessBankChecked }) => {
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const buttonStyles = {
    width: "7.813rem",
    height: "2.813rem",
    borderRadius: "4px",
  };

  const [accessbankconfig, setAccesBankConfig] = useState({  clientSecret: "", accountNumber: ""});

  const getconfigdetails = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setAccesBankConfig({ ...accessbankconfig, [name]: value });
  };

  const saveAccessBankConfigs = () => {
    HttpComponent({
      method: "POST",
      body: accessbankconfig,
      token: localStorage.getItem("X-Authorization"),
      url: `/api/v1/setupaccessbank`,
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccessShow({ state: true, message: "Config set successfully" });
        setAccesBankConfig({ clientSecret: "" });
        setTimeout(() => {
          setSuccessShow({ state: false, message: "" });
          setShowAccessBankChecked(false);
        }, 2000);
      } else {
        setErrorShow({ state: true, message: data?.response?.message });

        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 2000);
      }
    });
  };
  return (
    <Box sx={{ display: "flex", width: "100%", padding: "26px" }}>
      <form style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Account Number</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="accountNumber" value={accessbankconfig?.accountNumber} multiline required />
        </FormControl>

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Client Secret</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="clientSecret" value={accessbankconfig?.clientSecret} multiline required />
        </FormControl>

        {/* <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Client Id</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="clientId" value={accessbankconfig?.clientId} multiline required />
        </FormControl> */}

        {/* <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Resource Id</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="resourceId" value={accessbankconfig?.resourceId} multiline required />
        </FormControl> */}

        {/* <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Subscription Key</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="subscriptionkey" value={accessbankconfig?.subscriptionkey} multiline required />
        </FormControl> */}
        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: "0px", paddingBottom: "20px" }}>
          <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: "30px" }}>
            <Box sx={{ display: "flex" }}>
              {/* <Button sx={{
                                ...buttonStyles,
                                border: " solid 1px #002543",
                                color: "#002543",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    color: '#002543'
                                }
                            }}
                                onClick={() => showKCBMobileMoneySetup(false)}
                            >
                                Cancel
                            </Button> */}

              <Button
                sx={{
                  ...buttonStyles,
                  marginLeft: 1,
                  backgroundColor: " #032541",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "white",
                  },
                }}
                onClick={() => (accessbankconfig?.clientSecret ? saveAccessBankConfigs() : null)}
              >
                Save
              </Button>
            </Box>
          </Box>
        </div>
      </form>
    </Box>
  );
};

export default Accessbankdeposit;

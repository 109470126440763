import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, Grid, Paper, Slide, Tab, Typography } from "@mui/material"
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import HttpComponent from "../MakeRequest";
import Profile from "../../../images/large-profile.svg"
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import Chip from '@mui/material/Chip';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import WarningImg from "../../../images/warning.png"
import RestoreImg from "../../../images/restoreImg.svg"

import { forwardRef } from "react";
import { currencyconverter } from "../../../common/currencyconverter";
import DateFormatter from "../../../utils/dateFormatter";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsor</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Sponsor Profile</Typography>,
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));

const SponsorProfile = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [tabValue, setTabValue] = useState('ACTIVE')
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate()

    const [customerDetails, setCustomerDetails] = useState({})
    const [isloading, setIsloading] = useState(true)
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, customerId] = newqueryParamValue.split("?")
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false)
    const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false)

    const handleDeactivate = () => {
        setOpenDeactivateDialog(!openDeactivateDialog)
    }

    const handleActivate = () => {
        setOpenRestoreDialog(!openRestoreDialog)
    }



    //customer details

    function getCustomerdetails() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_number?customerId=${customerId}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCustomerDetails(data?.response?.data ? data?.response?.data[0] : {})
                } else {
                    setIsloading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCustomerdetails()
    }, [customerId])

    const sponsorColumns = [
        { field: "itemNumber", headerName: "Student ID", flex: 1 },
        { field: "parentName", headerName: "Student Name", flex: 1 },
        { field: 'SponsorName', headerName: 'Sponsor Name', flex: 1 },
        { field: 'maxAmountToSpend', headerName: 'Max Amount Per Day', flex: 1 },
        { field: 'sponsorTransactionCount', headerName: 'Transactions', flex: 1 },
        { field: 'sponsorTransactionTotal', headerName: 'Total Amount Spend', flex: 1 , renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.sponsorTransactionTotal)}</Typography>
        }},
    ]
    const RecentPaymentsColumns = [
        { field: "transactionID", headerName: "Receipt No", flex: 1 },
        { field: "parentName", headerName: "Batch No", flex: 1 },
        { field: 'createdAt', headerName: 'Date', flex: 1,renderCell:(params)=>{
            return <Typography>{DateFormatter(params?.row?.createdAt)}</Typography>
        } },
        { field: 'transamount', headerName: 'Amount', flex: 1, renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.transamount)}</Typography>
        } },
    ]

    const InvoicesColumns = [
        { field: "batchNo", headerName: "Batch No", flex: 1, renderCell: (params) => (
            <Typography
              onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
              sx={{ color: "#032541", textDecoration: "underline", fontSize:'12px', cursor: 'pointer' }}
            >
              {params.row.batchNo}
            </Typography>
          ), },
        { field: 'totalAmount', headerName: 'Total Amount', flex: 1, renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.totalAmount)}</Typography>
        } },
        { field: 'amountPaid', headerName: 'Amount Paid', flex: 1, renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.amountPaid)}</Typography>
        } },
        { field: 'amountDue', headerName: 'Amount Due', flex: 1 ,renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.amountDue)}</Typography>
        } },
        { field: 'paymentStatus', headerName: 'Status', flex: 1 , renderCell: (params) => 
        <Typography style={params?.row?.paymentStatus === 'Unpaid' ? 
            { color: "#dc3545" } : params?.row?.paymentStatus === 'Paid' ? { color: "#17ae7b" } : 
            params?.row?.paymentStatus === 'Partially Paid' ? { color: "#ff8503" } : params?.row?.paymentStatus === 'CANCELLED' ? { color: '#333333', fontWeight: 700, fontSize: "14px" } : 
            params?.row?.paymentStatus === 'DELETED' ? { color: '#9C9EA4', fontWeight: 700, fontSize: "14px" } : null}>{params?.row?.paymentStatus}</Typography>,}

    ]

    // function getStudentSponsored() {
    //     setIsloading(true)
    //     try {
    //         HttpComponent({
    //             method: "GET",
    //             url: `/api/get_sponsoredbycustomer?customerId=${customerId}&page=${pageState.page}&limit=${dataGridPageSize}`,
    //             body: null,
    //             token: localStorage.getItem("X-Authorization"),
    //         }).then((data) => {
    //             if (data?.status === 200 || data?.status === 201) {
    //                 console.log(data?.response, 'css')
    //                 setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count })
    //                 setIsloading(false)
    //             } else {
    //                 setIsloading(false)
    //             }
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     getStudentSponsored()
    // }, [dataGridPageSize, pageState.page])

    // generate sponsor invoice 

    function generateSponsorInvoice() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/generatesponsor-invoice`,
                body: {
                    sponsorId:customerId
                },
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: data?.response?.message })
                } else {
                    setIsloading(false)
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ ...errorShow, state: true, message: error?.message })
        }finally{
            setIsloading(false)
        }
    }

    let tabUrl = 
        tabValue === 'ACTIVE' ? `/api/get_sponsoredbycustomer?customerId=${customerId}&page=${pageState.page}&limit=${dataGridPageSize}` :
            tabValue === 'Invoices' ? `/api/get_invoice_batches?status=APPROVED&classification=&page=${pageState.page}&limit=${dataGridPageSize}&customerId=${customerId}&sponsorBatch=YES` :
                tabValue === "Recent" ? `/api/getsponsortransactionSummary?customerId=${customerId}&page=${pageState.page}&limit=${dataGridPageSize}` : null
    
    function getInvoiceBatches() {
        setPageState({ ...pageState, isLoading: true})
        try {
            HttpComponent({
                method: "GET",
                url: tabUrl,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count })
                } 
            })
        } catch (error) {
        }finally{
            setPageState({ ...pageState, isLoading: false})

        }
    }
    useEffect(() => {
        getInvoiceBatches()
    }, [tabValue, pageState.page, dataGridPageSize ,tabUrl])



    return (
        <Grid container display={'flex'} flexDirection={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

            <Grid item display={'flex'} alignContent={'center'} justifyContent={'space-between'} alignItems={'center'}>
                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Sponsor Profile</Typography>
                </Box>
                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button onClick={() => generateSponsorInvoice()} style={{ color: '#032541',fontWeight:700, textTransform: 'inherit', border:'1px solid #032541', width: '146px', height: '50px' }}>Generate Invoice</Button>
                    <Button onClick={() => setSuccessShow({ state: true, message: "coming soon" })} style={{ color: '#fff', textTransform: 'inherit', width: '146px', height: '50px', background: "#032541" }}>Edit</Button>
                    <Button onClick={handleActivate} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Deactivate</Button>
                </Box>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
                <Grid container width={'100%'} display={'flex'} spacing={1} justifyContent={'space-between'}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '100%', padding: '20px', height: '150px' }}>
                            <Box component={'div'} display={'flex'} sx={{ width: '100%', height: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                <Box component={'div'} flex={0.2} display={'flex'}>
                                    <Box component={'img'} src={Profile} />
                                </Box>
                                <Box component={'div'} flex={0.8}>
                                    <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
                                        <Typography variant="subtitle2">{customerDetails?.firstName} {customerDetails?.lastName}</Typography>
                                        <Chip size="small" label={customerDetails?.SponsorStatus} color={customerDetails?.SponsorStatus === "Active" ? "success" : "error"} />
                                    </Box>
                                    <Box component={'div'} display={'flex'} alignItems={'center'} gap={2}>
                                        <Typography sx={{ color: '#707070' }} variant="subtitle2">Type</Typography>
                                        <Typography variant="subtitle2">{customerDetails?.sponsorType}</Typography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} gap={5} mt={1}>
                                        <Box component={'div'} display={'flex'} alignItems={'center'} gap={2}>
                                            <PhoneInTalkIcon fontSize="small" />
                                            <Typography sx={{ color: '#707070' }} variant="subtitle2">{customerDetails?.phone ? customerDetails?.phone : 'N/A'}</Typography>
                                        </Box>
                                        <Box component={'div'} display={'flex'} alignItems={'center'} gap={2}>
                                            <EmailIcon fontSize="small" />
                                            <Typography sx={{ color: '#707070' }} variant="subtitle2">{customerDetails?.email ? customerDetails?.email : 'N/A'}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '100%', padding: '20px', height: '150px' }}>
                            <Box component={'div'} display={'flex'} sx={{ width: '100%', height: '100%' }} flexDirection={'column'}>
                                <Typography fontWeight={'700'} color={'#032541'} variant="subtitle2">Overall Statistics</Typography>
                                <Box component={'div'} sx={{ width: '100%', height: '100%' }} justifyContent={'space-between'} display={'flex'} mt={1}>
                                    <Box component={'div'} display={'flex'} flex={0.3} height={'100%'} alignItems={'center'} sx={{ border: '1px solid #e8e8e8', borderRadius: "10px" }}>
                                        <Box component={'div'} width={'100%'} flexDirection={'column'} display={'flex'} px={1}>
                                            <Typography sx={{ color: "#3da2f8", fontWeight: 700 }} variant="subtitle2">{customerDetails?.mysponsoredStudentsCount}</Typography>
                                            <Typography sx={{ color: '#000000' }} variant="subtitle2">Students Sponsored</Typography>
                                        </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} flex={0.3} height={'100%'} alignItems={'center'} sx={{ border: '1px solid #e8e8e8', borderRadius: "10px" }}>
                                        <Box component={'div'} width={'100%'} flexDirection={'column'} display={'flex'} px={1}>
                                            <Typography sx={{ color: '#17ae7b', fontWeight: 700 }} variant="subtitle2">0</Typography>
                                            <Typography sx={{ color: '#000000' }} variant="subtitle2">Payments Made</Typography>
                                        </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} flex={0.3} height={'100%'} alignItems={'center'} sx={{ border: '1px solid #e8e8e8', borderRadius: "10px" }}>
                                        <Box component={'div'} width={'100%'} flexDirection={'column'} display={'flex'} px={1}>
                                            <Typography sx={{ color: '#dc3545', fontWeight: 700 }} variant="subtitle2">0</Typography>
                                            <Typography sx={{ color: '#000000' }} variant="subtitle2">Pending Invoices</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                        <AntTab label="Student Sponsored" value="ACTIVE" />
                        <AntTab label="Recent Payments" value="Recent" style={{ marginLeft: '20px' }} />
                        <AntTab label="Recent Invoices" value="Invoices" style={{ marginLeft: '20px' }} />
                    </AntTabs>
                    <TabPanel value="ACTIVE" style={{ marginLeft: '-30px' }}>
                        <Grid item width={"100%"} mt={5}>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={sponsorColumns}
                                rows={pageState.data}
                                getRowId={row => row?._id || Math.random()} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Recent" style={{ marginLeft: '-30px' }}>
                        <Grid item width={"100%"} mt={5}>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={RecentPaymentsColumns}
                                rows={pageState.data}
                                getRowId={row => row?._id || Math.random()} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Invoices" style={{ marginLeft: '-30px' }}>
                        <Grid item width={"100%"} mt={5}>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.total}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={InvoicesColumns}
                                rows={pageState.data}
                                getRowId={row => row?._id || Math.random()} />
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>
            <Dialog
                open={openRestoreDialog}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        height: "300px",
                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                    },
                }}
                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "1", backdropFilter: "blur(1px)", } }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <Box component={'img'} src={RestoreImg} sx={{height:"70px" , width:"70px"}} alt='approve' />
                    <Box component={'div'} sx={{ display: "flex", gap: 2, flexDirection: "column", alignContent: "center", textAlign: 'center', alignItems: "center", width: '100%' }}>
                        <Typography sx={{ color: "#032541", fontWeight: '700', fontSize: "14px" }} variant="subtitle1">Reactivate  Sponsor</Typography>
                        <Typography sx={{ color: "#707070" }} variant="subtitle2"><span style={{ color: "#032541" }}>{customerDetails?.firstName} {customerDetails?.lastName}</span> will  have access to system  , <span style={{ color: "#032541" }}>{customerDetails?.mysponsoredStudentsCount} students</span> will automatically be Activated</Typography>
                        <Typography sx={{ color: '#707070', fontSize: "12px" }} variant="subtitle2">Do you wish to continue ? </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", pb: 3, justifyContent: "center", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <Button onClick={handleActivate} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                    <Button style={{ backgroundColor: "#17ae7b", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Reactivate</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeactivateDialog}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        height: "300px",
                        width: "600px",
                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                    },
                }}
                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "1", backdropFilter: "blur(1px)", } }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <img src={WarningImg} alt='approve' />
                    <Box component={'div'} sx={{ display: "flex", gap: 2, flexDirection: "column", alignContent: "center", textAlign: 'center', alignItems: "center", width: '100%' }}>
                        <Typography sx={{ color: "#032541", fontWeight: '700', fontSize: "14px" }} variant="subtitle1">Deactivate  Sponsor</Typography>
                        <Typography sx={{ color: "#707070" }} variant="subtitle2"><span style={{ color: "#032541" }}>{customerDetails?.firstName} {customerDetails?.lastName}</span> will no longer have access to system  , <span style={{ color: "#032541" }}>{customerDetails?.mysponsoredStudentsCount} students</span> will automatically be deactivated</Typography>
                        <Typography sx={{ color: '#707070', fontSize: "12px" }} variant="subtitle2">Do you wish to continue ? </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", pb: 3, justifyContent: "center", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <Button onClick={handleDeactivate} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                    <Button style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export { SponsorProfile }